<template>
  <campaign-layout
    :showMenu="computed_debug"
    :showBack="computed_debug"
    :pageTitle="$t('pageTitle.Social')"
  >
    <div v-if="sources">
      <template v-for="(source, k) in sources" :key="source.key">
        <div>
          <div v-if="source.items && source.items.length">
            <h3>{{ $t("campaign_social.share_" + source.name) }}</h3>
            <transition-group
              name="block-slide-in"
              :style="{ '--total': source.items.length }"
            >
              <template v-for="(item, i) in source.items" :key="item.key">
                <ion-card
                  :style="{ '--i': i }"
                  v-if="showListSingle && showList && showList[k]"
                  @click="$router.push(item.url)"
                >
                  <ion-card-header>
                    <div
                      v-if="item.progresso_attuale !== false"
                      class="percentage"
                    >
                      {{ item.progresso_attuale }} /
                      {{ item.progresso_obiettivo }}
                    </div>
                    <div class="title">
                      <ion-card-title class="text-20">{{
                        item.title
                      }}</ion-card-title>
                    </div>
                    <div
                      v-if="item.progresso_attuale !== false"
                      class="common-bar-container"
                    >
                      <common-progress-bar
                        :value="
                          item.progresso_attuale / item.progresso_obiettivo
                        "
                      ></common-progress-bar>
                    </div>
                  </ion-card-header>
                </ion-card>
              </template>
            </transition-group>
          </div>
          <br />
          <br />
          <br />
        </div>
      </template>
    </div>
  </campaign-layout>
</template>

<script>
import { arrowForward } from "ionicons/icons";
import ApiService from "../../common/service.api";
import { shortenTextForTitle, translate } from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";

import CampaignService from "../../common/mixins/Campaign";
import CommonProgressBar from "@/components/CommonProgressBar";
import ItemRanking from "@/components/ItemRanking";
import { IonProgressBar } from "@ionic/vue";

export default {
  name: "CampaignSocial",
  data() {
    return {
      showListSingle: false,
      showList: null,
      sources: false,
    };
  },
  components: {
    CommonProgressBar,
    CampaignLayout,
    ItemRanking,
    IonProgressBar,
  },
  mixins: [CampaignService],
  methods: {},
  ionViewWillEnter() {
    const route = this.$route;
    const { id } = route.params;
    this.campaignId = id;
    this.getCampaign(id).then((data) => {
      this.campaignData = data;
    });
    const sources = [
      {
        name: "landings",
        endpoint: "CampaignManager/GetUserLandings/" + this.campaignId,
      },
      {
        name: "facebook",
        endpoint: "SocialShare/GetFacebookPosts/" + this.campaignId,
      },
      {
        name: "linkedin",
        endpoint: "SocialShare/GetLinkedinPosts/" + this.campaignId,
      },
    ];
    let showList = [];
    sources.forEach((source, i) => {
      showList[i] = false;
      source.key = i + "_" + 0;
      ApiService.get(source.endpoint, {
        // ApiService.get("CampaignManager/GetUserCampaings" , {
      }).then((res) => {
        // res.data = [
        //   {    "LandingCustomCode": "string",
        //     "url_cover": "string",
        //     "url_profile": "string",
        //     "title": {"it-IT":"test"},
        //     "body": {"it-IT":"test2"},
        //     perc_completamento:10,
        //     // perc_completamento:false,
        //     id:1},
        //     ];
        this.sources[i].items = res.data.map((item) => {
          item.key = source.name + "_" + item.id;
          switch (source.name) {
            case "landings":
              item.title = translate(item.title);
              item.body = translate(item.body);
              item.url = this.$router.resolve({
                name: "campaign_social_post",
                params: {
                  id: this.campaignId,
                  postid: item.id,
                  type: "landing",
                  code: item.LandingCustomCode,
                },
              }).href;
              break;
            case "facebook":
              item.title = shortenTextForTitle(item.text);
              item.body = "";
              item.progresso_attuale = item.progresso_attuale
                ? item.progresso_attuale
                : 0;
              item.progresso_obiettivo = item.progresso_obiettivo
                ? item.progresso_obiettivo
                : 100;
              item.url = this.$router.resolve({
                name: "campaign_social_post",
                params: {
                  id: this.campaignId,
                  postid: item.id,
                  type: "facebook",
                  code: item.postID,
                },
              }).href;
              break;
            case "linkedin":
              item.title = shortenTextForTitle(item.text);
              item.body = "";
              item.progresso_attuale = item.progresso_attuale
                ? item.progresso_attuale
                : 0;
              item.progresso_obiettivo = item.progresso_obiettivo
                ? item.progresso_obiettivo
                : 100;
              item.url = this.$router.resolve({
                name: "campaign_social_post",
                params: {
                  id: this.campaignId,
                  postid: item.id,
                  type: "linkedin",
                  code: item.postID,
                },
              }).href;
              break;
          }

          // item.perc_completamento = false;

          return item;
        });
        this.sources[i].key = i + "_" + this.sources[i].items.length;
        setTimeout(() => {
          this.$nextTick(() => {
            this.showList[i] = true;
          });
        }, 1);
      });
    });
    this.showList = showList;
    this.sources = sources;
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showListSingle = true;
      });
    }, 1);
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
}

.percentage {
  width: 100%;
  float: left;
  text-align: right;
  font-weight: bold;
  color: var(--text-color-light);
}

.title {
  display: block;
  clear: both;
}

ion-card {
  ion-card-title {
    color: var(--text-color-light);
  }

  ion-button {
    margin-top: 15px;
  }

  ion-button::part(native) {
    background: var(--text-color-light);
    color: var(--ion-color-primary);

    &:hover {
      background: var(--text-color-light);
    }
  }
}
</style>
